<script setup>
import { getCurrentInstance } from 'vue'
console.log('INSTANCE', getCurrentInstance())
</script>

<template>
    <div class="custom-style">
        <h3>Custom Component</h3>
    </div>
</template>

<style lang="scss" scoped>
.custom-style {
    padding: 10px;
}
</style>