<script setup>
import {reactive} from 'vue'
import {State, navigate} from 'manager'

const page = reactive({
    account: State.auth.account,
    cloud: State.cache.clouds[0],
    title: State.app.title || State.app.display.title,
    version: State.app.display.version,
})

function cancel() {
    navigate('/')
}
</script>

<template>
    <div class="page about">
        <h3 class="mb-5">About {{ page.title }}</h3>

        <vu-input v-model="page.version" label="Version" :disabled="true" type="text" cols="12" />
        <vu-input v-model="page.account.id" label="Account ID" :disabled="true" type="text" cols="12" />
        <vu-input v-model="page.cloud.id" label="Device Cloud ID" :disabled="true" type="text" cols="12" />

        <div class="mb-5">
            <h3>Terms</h3>
            <a href="https://www.embedthis.com/about/terms.html">https://www.embedthis.com/about/terms.html</a>
        </div>

        <div class="mb-5">
            <h3>Privacy</h3>
            <a href="https://www.embedthis.com/about/privacy.html">https://www.embedthis.com/about/privacy.html</a>
        </div>
        <vu-form>
            <div class="actions">
                <v-btn size="small" @click="cancel">Cancel</v-btn>
            </div>
        </vu-form>
    </div>
</template>

<style lang="scss" scoped>
.about {
    padding-top: 22px;
    .actions {
        margin: 12px 0 0 -12px;
    }
}
</style>
